.articleCardSkeleton {
    height: 540px;
    width: 872px;
    max-width: 100%;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    .articleCardSkeletonImage {
        width: 460px;
        margin-left: -10px;
        z-index: -1;
    }
    .articleCardSkeletonLeftContent {
        padding: 32px;
        z-index: 1;
        width: 412px;
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
    }
    .articleCardSkeletonLeftContentButton {
        position: absolute;
        bottom: 32px;
    }

    .articleCardSkeletonLeftContentTitle,
    .articleCardSkeletonLeftContentDescription {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        & > :not(:last-of-type) {
            margin-bottom: 8px;
        }
    }
}

.articleCardSkeleton.small {
    flex-direction: column;
    border: 0;
    width: 424px;
    max-width: 100%;
    .articleCardSkeletonImage {
        margin-left: 0px;
        margin-bottom: -10px;
        height: 270px;
        width: 100%;
    }
    .articleCardSkeletonLeftContent {
        padding: 20px 32px 32px 32px;
        height: 270px;
        width: 424px;
        max-width: 100%;
    }
    .articleCardSkeletonLeftContentTitle {
        margin-top: 22px;
    }
    .articleCardSkeletonLeftContentDescription {
        margin-top: 16px;
    }
    .articleCardSkeletonLeftContentTitle,
    .articleCardSkeletonLeftContentDescription {
        & > :not(:last-of-type) {
            margin-bottom: 8px;
        }
    }
    @media screen and (max-width: 768px) {
        height: unset !important;

        .articleCardSkeletonImage {
            height: 159px !important;
        }
        .articleCardSkeletonLeftContent {
            height: auto !important;
            padding: 16px;
        }
    }
}

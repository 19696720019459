@mixin buttons-style($textColor, $hoverColor, $backgroundColor, $borderColor: none) {
    width: fit-content;
    border-radius: 100px;
    cursor: pointer;
    color: $textColor;

    transition: background-color 0.3s ease-in;

    svg > path {
        stroke: $textColor !important;
    }
    &:hover {
        color: $hoverColor;
        svg > path {
            stroke: $hoverColor !important;
        }

        background-color: $backgroundColor !important;
    }
    border: $borderColor;
}

@mixin paddingTopByPercent($width, $height, $result: 0%) {
    $result: calc(($height / $width) * 100%);
    padding-top: $result;
}
@mixin skeleton-load() {
    border-radius: 4px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #e4ecf7;
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba(#fffeff80, 0) 0, rgba(#fffeff80, 0.2) 20%, rgba(#fffeff80, 0.5) 60%, rgba(#fffeff80, 0));
        animation: shimmer 2s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
    @-moz-keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
    @-webkit-keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
    @-moz-keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
@mixin fadeIn-fadeOut() {
    animation: fade 0.5s ease-in;

    @keyframes fade {
        0%,
        100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
}

/* Safari */
@-webkit-keyframes video-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes video-spin {
    0% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(90deg);
    }
    70% {
        transform: rotate(240deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

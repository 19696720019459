@import '../../styles/styles';

.signUpCard {
    padding: 32px;
    position: relative;
    height: 540px;
    width: 100%;
    max-width: 424px;
    border-radius: 12px;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    background-color: #b2fce4;
    z-index: 0;
    overflow: hidden;
}
.signUpCard:hover {
    box-shadow: 0px 8px 32px rgb(0 0 0 / 15%);
}
.backgroundImage {
    position: absolute;
    left: -16px;
    bottom: -32px;
    z-index: -1;
    svg {
        width: 557px;
        height: 487px;
    }
}
.title {
    margin: 0;
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    color: #000;
    margin-bottom: 24px;
}
.description {
    margin: 0;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    color: #000;
}
.buttonSubscribe {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    background-color: #000;
    border-radius: 100px;
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    position: absolute;
    bottom: 32px;
    left: 32px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;

    span {
        color: white;
        margin-right: 12px;
        word-break: break-all;
    }

    svg {
        margin-left: 8px;
        transition: transform 0.3s;

        path {
            stroke: white;
        }
    }

    &:hover {
        background-color: white;
        color: black;
        span {
            color: black;
        }
        svg {
            path {
                stroke: black;
            }
        }
    }
}

@media screen and(max-width:768px) {
    .backgroundImage {
        svg {
            width: 438px;
            height: 383px;
        }
    }
    .signUpCard {
        padding: 24px 16px 16px 16px;
        height: 375px;
    }
    .title {
        margin-bottom: 16px;
        font-size: 40px;
        line-height: 52px;
    }
    .description {
        font-size: 24px;
        line-height: 32px;
    }
    .buttonSubscribe {
        left: 16px;
        bottom: 16px;
        right: 16px;
        width: auto;
    }
}

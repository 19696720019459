.dropdownExplore {
    position: absolute;
    top: 72px;
    left: 0;

    color: white;
    visibility: hidden;
    .dropdownContainer {
        padding: 32px;
        width: 1320px;
        max-width: 100%;

        background: #000000;
        border-radius: 8px;
        display: flex;

        .dropdownTitle {
            display: flex;

            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: white;
            padding-bottom: 8px;
            align-items: flex-start;
            border-bottom: 1px solid #333333;

            a {
                color: inherit;
            }

            svg {
                margin-right: 16px;
            }
        }
    }
    .latestArticles {
        position: relative;
        margin-right: 24px;
    }
    .latestArticlesContainer {
        display: flex;
    }
    .latestArticlesListWrapper {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        margin-bottom: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;

        width: 416px;
        max-width: 100%;

        .articleTypeTitle {
            font-weight: bold;
            margin-bottom: 16px;

            color: white;
        }
        li {
            margin-bottom: 16px;
        }
        .articleTypeName {
            text-decoration: none;
            color: white;
        }
        .articleTypeName:hover {
            color: #b2fce4;
        }
    }

    .exploreButtonWrapper {
        cursor: pointer;
        position: relative;
        bottom: 0;
        justify-content: center;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 100px;
        background: #000000;
        color: white;
        display: flex;

        align-items: center;
        justify-self: center;
        padding: 12px 24px;
        width: fit-content;
        transition: background 0.3s ease-in-out;
        .exploreButtonText {
            color: white;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            margin-right: 12px;
            white-space: nowrap;
        }
        path {
            stroke: white;
        }
        a {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        &:hover {
            background: #b2fce4;
            .exploreButtonText {
                color: black;
            }
            path {
                stroke: black;
            }
        }
    }
    .moreResource {
        width: 392px;
        max-width: 100%;
    }
    .moreResourceWrapper {
        margin-top: 32px;
        display: flex;

        flex-direction: column;

        .moreResourceContainer {
            display: flex;
            color: white;
            text-decoration: none;
            justify-content: space-between;
            width: 100%;
            max-width: 100%;

            &:hover {
                color: #b2fce4;
                .moreResourceViewMoreIcon {
                    path {
                        stroke: #b2fce4;
                    }
                }
            }
        }
        .moreResourceContainer:not(:last-child) {
            margin-bottom: 32px;
        }
        .moreResourceName {
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
        }
        .moreResourceDescription {
            font-size: 14px;
            line-height: 22px;
        }
        .moreResourceViewMoreIcon {
            path {
                stroke: white;
            }
        }
    }
}
.dropdownExplore.toggleExploreOn {
    visibility: visible;
}
@media screen and(min-width:1439px) {
    .dropdownExplore {
        left: 60px !important;
    }
}
@media screen and(min-width:1366px) {
    .dropdownExplore {
        left: 20px;
    }
}
@media screen and(max-width:1320px) {
    .dropdownExplore {
        left: 0;
        right: 0;
    }
}
@media screen and(max-width:1112px) and (min-width: 770px) {
    .dropdownExplore {
        padding: 0 60px;
    }
    .dropdownContainer {
        padding: 0 !important;
        flex-direction: column !important;
    }
    .latestArticles {
        margin-bottom: 40px;
    }
}
@media screen and(max-width:768px) {
    .dropdownExplore {
        top: 88px;
        left: 15px;
        right: 15px;
        bottom: 0;
        -webkit-transform: translate3d(0, 0, 0);
        z-index: 500;
    }
    .dropdownContainer {
        padding: 0 !important;
        flex-direction: column !important;
        display: block;
    }
    .latestArticles {
        margin-right: 0 !important;
    }
    .latestArticlesContainer {
        flex-direction: column !important;
        margin-bottom: 40px;
        .latestArticlesListWrapper {
            margin: 0;
            .articleTypeTitle {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 16px;
            }
            .exploreButtonWrapper {
                position: relative;
                margin: 0 auto;
                margin-top: 8px;
                width: 475px;
                max-width: 100%;
                margin-bottom: 16px;
            }
        }
        .latestArticlesListWrapper:not(:last-child) {
            margin-top: 24px;
            margin-bottom: 64px;
        }
    }
    .moreResource {
        padding-bottom: 32px;
        width: 100% !important;
    }
}

.dropdownSelector {
    background-color: transparent;
    position: relative;
    width: 200px;
    :global {
        .dropdown-body {
            display: none;
        }
        .dropdown-header {
            white-space: nowrap;
            padding: 20px 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            justify-content: space-between;
        
            border: 0.5px solid white;
            border-radius: 16px;
            width: 100%;
            color: white;
            transition: background 0.15s ease-in-out;
        }
        .dropdown-header.dropdown-open {
            border-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .dropdown-body {
            display: none;
            background: #000;
            color: white;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            max-height: 0;
            transition: max-height 0.3s ease-in-out;
            padding-bottom: 20px;
        }

        .dropdown-body.dropdown-open {
            display: block;
            position: absolute;
            z-index: 1;
            max-height: 400px;
            transition: max-height 0.3s ease-in-out;
            width: 100%;
            border: 0.5px solid white;
            border-top: 0;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }

        .dropdown-item {
            a {
                color: white;
                text-decoration: none;
            }

            text-align: left;
            padding: 8px 16px;
            transition: background-color 0.15s ease-in-out;
        }

        .dropdown-item:hover {
            cursor: pointer;
            a {
                color: #b2fce4;
            }
            color: #b2fce4;
        }

        .icon-right {
            margin-left: 8px;
            height: 21px;
            svg {
                width: 21px;
                height: 21px;
            }
            path {
                stroke: white;
            }
        }
    }
}
@media screen and(max-width:768px) {
    .dropdownSelector {
        width: 100%;
    }
}

@import '../../styles/styles';

.formSearchBlog {
    display: flex;
    position: relative;
    align-items: center;

    width: 16px;
    max-width: 100%;
    transition: width 0.5s ease-in-out;

    .inputSearch {
        width: 0;
        max-width: 100%;
        border-radius: 0;
        height: 38px;
        background-color: transparent;
        border: 0;
        color: white;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        padding: 0;
        outline: none;
        display: inline-block;
        border-bottom: 1px solid #666666;
        max-width: 100%;

        caret-color: #b2fce4;

        padding: 0;
        width: 0;
    }
    .inputSearch:focus,
    .inputSearch:active {
        border-color: white;
    }

    .clearInput {
        cursor: pointer;
        position: absolute;
        right: 0;
        background: none;
        border: 0;
        svg {
            width: 0;
            height: 0;
        }
        transition: width 0.5s ease-in-out;
    }
    .buttonSearch {
        z-index: 1;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        outline: none;
        border: none;
        border-radius: 50%;
        background: none;
        border: 0;
        margin-right: 16px;
        path {
            stroke: white;
        }

        &:hover {
            path {
                stroke: #b2fce4;
            }
        }
    }

    &.formShown {
        width: 401px;
        transition: width 0.5s ease-in-out;
        .inputSearch {
            padding: 8px 24px 8px 0;
            width: 100%;
        }
        .clearInput {
            svg {
                width: auto !important;
                height: auto !important;
            }
        }
        .suggestionsContainer,
        .noSuggestions {
            display: block;
        }
        @media only screen and (max-width: 1112px) {
            width: 100%;
        }
    }
}

.suggestionsContainer {
    position: absolute;
    border-radius: 8px;
    background: black;
    top: 46px;
    right: 0;
    width: calc(100% - 32px);
    padding: 16px;

    display: none;
}

.suggestionCategoryTitle {
    font-size: 10px;
    line-height: 16px;

    text-transform: uppercase;

    color: white;

    font-weight: bolder;

    cursor: default;
    user-select: none;
    pointer-events: none;
}

.suggestionCategoryNoneFound {
    color: white;

    font-size: 14px;
    line-height: 22px;

    padding-top: 8px;
    padding-bottom: 8px;
}

.suggestionCategoryList {
    margin: 0;
    padding: 0;

    li {
        cursor: pointer;
        padding: 0;
        display: -moz-box;
        display: flex;
        flex-direction: row;
        color: white;
        align-items: center;

        padding: 8px 0;

        font-size: 14px;
        line-height: 22px;

        padding-top: 8px;
        padding-bottom: 8px;

        a {
            display: flex;
            max-width: 100%;
        }

        * {
            transition: color 0.2s, stroke 0.2s;
        }

        svg,
        path {
            stroke: white;
            stroke-width: 2;
        }
    }

    li:hover {
        path {
            stroke: #b2fce4;
        }

        &,
        * {
            color: #b2fce4 !important;
            font-weight: bold;
        }
    }
}

.suggestionCategoryListText {
    margin-left: 16px;

    display: block;

    max-width: 87%;
    white-space: nowrap;
    overflow: hidden;

    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 0.6);
}

.suggestionMatch {
    color: white;
    font-weight: bold;
}

.noSuggestions {
    position: absolute;
    border-radius: 8px;
    background: black;
    top: 46px;
    right: 0;
    width: calc(100% - 32px);
    padding: 8px 12px;
    list-style: none;
    display: none;

    p {
        color: white;
        margin: 0;
        padding: 8px;
    }
}

.spinnerLoadMore {
    animation: video-spin 1s linear infinite;
    border: 5px solid #b2fce43b;
    border-radius: 50%;
    border-top: 5px solid #b2fce4;
    display: block;
    height: 30px;
    margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 30px;
}

@media screen and(max-width:768px) {
    .formSearchBlog {
        .buttonSearch.clicked {
            svg {
                height: 24px;
                width: 24px;
            }
        }
        .suggestionsContainer {
            padding: 0;
        }
    }
}

@import '../../styles/_styles.scss';
.articleHeaderWrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 400;
    background: #000000;
    backdrop-filter: blur(32px);
}
.articleHeaderContainer {
    height: 64px;
    width: 1440px;
    max-width: 100%;
    padding: 0 60px;

    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: center;
}
.logoWrapper {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
}
.iconAfterPay {
    margin: 0 8px 0 24px;
    height: 26px;
}
.businessIcon {
    svg {
        display: none;
    }
    svg:first-child {
        display: block;
    }
}

.buttonExplore {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4em;
    text-align: left;
    justify-content: center;
    margin-left: 32px;
    color: white;
    &:hover {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 100px;
    }
    .iconDown {
        margin-left: 8px;
        height: 18px;
        svg {
            width: 12px;
            height: 12px;
        }
        path {
            stroke: white;
        }

        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
    }
}

.businessHomepageLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.buttonExplore.buttonActive {
    background-color: white;
    color: black;
    border-radius: 100px;
    transition: background-color 0.2s ease-in-out;
    .iconDown {
        path {
            stroke: black;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-180deg);
        transition: all 0.2s ease-in-out;
    }
}
.menuRight {
    display: flex;
    flex-direction: row;
    align-items: center;

    .buttonSignIn {
        margin-right: 8px;
    }
}

.iconSearch {
    path {
        stroke: white;
    }
    cursor: pointer;
}
.verticalLine {
    height: 24px;
    width: 1px;
    background: #ffffff;
    opacity: 0.5;
    margin: 0 16px;
}
.buttonSignIn,
.buttonSignUp {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 12px;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;

    color: black;
}
.buttonSignIn {
    background-color: transparent;
    color: white;
}
.buttonSignUp {
    background-color: white;
    color: black;
}

.buttonToggleDrawer {
    .iconMenu {
        height: 24px;
        width: 24px;
        cursor: pointer;
        path {
            stroke: white;
        }
    }
}

@media screen and (max-width: 768px) {
    .articleHeaderWrapper {
        z-index: 3;
    }
    .articleHeaderContainer {
        padding: 0 15px;
    }

    .logoAfterPayBusiness {
        width: 215px;
        margin-right: 0;
    }
    .iconAfterPay {
        margin-left: 12px;
        height: 20px;

        svg {
            width: 102px;
            height: 20px;
        }
    }
    .businessIcon {
        svg:first-child {
            display: none;
        }
        svg:last-child {
            display: block;
        }
    }
}

@media screen and(max-width:1112px) {
    .articleHeaderContainer.toggleMenu {
        background-color: #000;
        position: fixed;
    }
}
/* =======Drawer Menu =========  */

@media screen and(min-width:1112px) {
    .drawerMenu {
        display: none !important;
    }
}
.drawerMenu {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: #000;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 64px;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px 15px 0px 15px;
    opacity: 1;
    -webkit-animation: display-none-transition 0.5s both;
    animation: display-none-transition 0.5s both;

    .iconMenu {
        path {
            stroke: white;
        }
    }
    .drawerMenuButton {
        height: 32px;
        display: flex;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .accountGroup {
        height: 32px;
        display: flex;
        opacity: 1;
        -webkit-animation: display-none-transition 0.5s both;
        animation: display-none-transition 0.5s both;
        .buttonSignIn {
            margin-right: 8px;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 0 60px;
    }
}

@-webkit-keyframes display-none-transition {
    0% {
        opacity: 0;
    }
}
@keyframes display-none-transition {
    0% {
        opacity: 0;
    }
}

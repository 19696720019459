.categoriesList {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    color: #4d4d4d;
}
.categoryName {
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: fit-content;
    padding: 5px 16px;
    border-radius: 25px;
    color: #4d4d4d !important;
    text-align: center;
    transition: color .3s, border 0.3s ease-in;
    &:hover {
        color: #000 !important;
        border: 1px solid #000;
    }
}
.categoryName:not(:last-child) {
    margin-right: 8px;
}
.categoryName {
    margin-bottom: 8px;
}
.categoriesList.SMALL {
    .categoryName {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        padding: 2px 10px 0px;
    }
}

.categoryName.white {
    color: white!important;
    border: 1px solid white;
}

@import "../../styles/styles";

.articleGrid {
    margin: 0 auto;
    max-width: 100%;
    transition: all 0.3s;

    transition-delay: 0.3s;
    width: 1440px;
}

.articlesContainer {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, calc(33% - 12px));
    -webkit-transition: all 1s;
    transition: all 1s;
}
.articleLarge {
    grid-column: span 2;
    width: 100%;
}

.buttonLoadMore {
    align-items: center;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    transition: background-color 0.3s ease-in-out;
    width: fit-content;

    &:hover {
        background-color: black;
        span {
            color: white;
        }
        path {
            stroke: white;
        }
    }
}
.buttonLoadMoreContent {
    align-items: center;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    span {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        margin-right: 12px;
    }
}

.buttonLoadMore.loading {
    display: none;
}
.buttonLoadMore.loaded {
    display: flex;
}

.spinnerLoadMore {
    animation: video-spin 1s linear infinite;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #555;
    display: none;
    height: 30px;
    margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    text-align: center;
    width: 30px;
}

.spinnerLoadMore.loading {
    display: block;
}
.loadMoreContainer {
    height: 240px;
    padding-top: 64px;
}

@media screen and(max-width:1366px) {
    .articlesContainer {
        justify-content: start;
        // grid-template-columns: repeat(2, auto);
        justify-items: start;
    }
    .articleLarge {
        grid-column: span 2;
    }
}
@media screen and(max-width:1200px) {
    .articlesContainer {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and(max-width:992px) {
    .articlesContainer {
        // grid-template-columns: repeat(auto-fill, minmax(345px, 424px));
        grid-template-columns: 1fr;
        justify-content: center;
        justify-items: center;
    }
    .articleLarge {
        grid-column: auto;
    }
    .loadMoreContainer {
        height: 128px;
        padding-top: 16px;
    }
}

@media screen and (max-width: 768px) {
    .articleGrid {
        padding-left: 15px;
        padding-right: 15px;
    }
    .articlesContainer {
        gap: 16px;
        > div {
            min-height: 375px;
        }
    }
    .articleLarge {
        grid-column: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: fit-content;
    }
    .articleGrid {
        padding: 0 15px;
    }
    .spinnerLoadMore,
    .buttonLoadMore {
        margin: 0px auto 64px auto;
        max-width: 100%;
    }
    .buttonLoadMoreContent {
        max-width: 100%;
        width: 345px;
    }
}

.articleFooterContainer {
    padding: 64px 60px 40px 60px;
    position: relative;
    width: 100%;

    background-color: black;
    display: flex;
    flex-direction: column;
}

.articleFooterInner {
    margin-left: auto;
    margin-right: auto;
}

.footerTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: white;
    margin-bottom: 16px;
}
.footerBlock {
    a {
        color: white;
        text-decoration: none;
        &:hover {
            color: #b2fce4;
        }
    }
    font-size: 14px;
    line-height: 22px;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 224px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.footerTop {
    margin-bottom: 40px;
}
.footerTop,
.footerBottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.footerBottom {
    .footerBlock {
        font-size: 12px;
        line-height: 20px;
    }
    margin-bottom: 40px;
}
.footerLocation {
    position: relative;
}

.logoAfterPay {
    height: 21px;

    svg {
        width: 108px;
        height: 21px;
    }
}

@media screen and(max-width:768px) {
    .articleFooterContainer {
        padding: 64px 15px 32px 15px;
        height: auto;
    }
    .footerTop {
        margin-bottom: 0px;
        > div {
            margin-bottom: 32px;
        }
    }

    .footerLocation {
        width: 100%;
        :global {
            .dropdown-warpper {
                margin-left: 0px;
            }
        }
    }
    .footerBottom {
        margin-bottom: 20px;
        .footerBlock {
            margin-bottom: 45px;
        }
    }
    .footerBlock {
        width: 170px;
    }

    .logoAfterPay {
        position: initial !important;
    }
}

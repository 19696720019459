@import '../../styles/styles';

.threeLinesLimit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.articleCard {
    height: 540px;
    max-height: 100%;
    width: 100%;
    max-width: 872px;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 12px;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: black;
    position: relative;
    transform: rotate(0);
    
    &.fixedWidth {
        width: 872px;
        max-width: 100%;
    }
    &:hover {
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
        .articleCardImage {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    position: relative;
}

.articleCardImageWrapper {
    position: relative;
    overflow: hidden;
    margin-left: -10px;
    width: 100%;
    max-width: 470px;
    max-height: 100%;
    height: 100%;
    // Large card have the container with height > width
    // @include paddingTopByPercent(540,460);
}
.articleCardImageWrapper.loading {
    padding: 0;
}
.articleCardImage {
    transform: rotate(0);
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s;
}
.articleCardContentWrapper {
    max-width: 412px;
    width: 100%;
    padding: 32px;
    background-color: white;
    border-radius: 12px;
    position: relative;
    border-right: 1px solid #e5e5e5;

    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    overflow: hidden;

    position: relative;
}
.categoriesList {
    margin-bottom: 16px;
    z-index: 1;
}

.articleCardContent {
    max-height: 100%;
    overflow: hidden;
}
.articleCardContentTitle {
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 16px;
}
.articleCardContentDescription {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: #4d4d4d;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}
.articleCardButtonReadMore {
    bottom: 32px;
    position: absolute;
    font-weight: bold;
    padding: 12px 24px;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    z-index: 1;
    transition: background-color 0.3s ease-in;
    white-space: nowrap;
    svg {
        margin-left: 8px;

        transition: transform 0.3s;
    }

    &:hover {
        svg {
            transform: translateX(4px);
        }
    }

    @include buttons-style(#000000, #000000, #b2fce4, 1px solid #e5e5e5);
}
.articleCard.small {
    flex-direction: column;
    border: 0;
    width: 100%;
    max-width: 424px;
    height: auto;

    .articleCardImageWrapper {
        margin-bottom: -10px;
        width: 100%;
        margin-left: 0;
        height: 270px;
        @include paddingTopByPercent(424, 270);
    }
    .articleCardContentWrapper {
        width: 100%;
        max-width: 786px;

        padding: 32px;
        border: 1px solid #e5e5e5;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 280px;
        max-height: 100%;
    }
    .categoriesList {
        margin-bottom: 10px;
    }
    .articleCardContentTitle {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .articleCardContentDescription {
        font-size: 14px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .articleCardButtonReadMore {
        display: none;
    }
    @media screen and(max-width:768px) {
        .articleCardContentWrapper {
            padding: 16px;
            min-height: 227px;
            height: auto;

            box-sizing: border-box;
            margin-top: -1px;
        }
        .categoriesList {
            margin-bottom: 8px;
        }
        .articleCardImageWrapper {
            height: auto;
            @include paddingTopByPercent(300, 159);
        }
    }
    @media screen and(max-width:768px) {
        min-height: 375px;
        height: auto;
    }
}

@media screen and(max-width:1200px) {
    .articleCard {
        max-width: 100%;
        &.small {
            max-width: 100%;
        }

        .articleCardImageWrapper {
            max-width: unset;
        }
    }
}

@media screen and(max-width:992px) {
    .articleCard {
        max-width: 872px;
        &.small {
            max-width: 424px;
        }

        .articleCardImageWrapper {
            max-width: 470px;
        }
    }
}

@media screen and(max-width:768px) {
    .articleCard.large {
        flex-direction: column;
        border: 0;
        width: 100%;
        max-width: 424px;

        .articleCardImageWrapper {
            margin-bottom: -10px;
            width: 100%;
            margin-left: 0;
            @include paddingTopByPercent(300, 159);
        }
        .articleCardContentWrapper {
            width: 100%;
            max-width: 786px;

            border: 1px solid #e5e5e5;
            overflow: hidden;
            text-overflow: ellipsis;

            max-height: 100%;
            padding: 16px !important;
            min-height: 227px;
            height: auto;

            box-sizing: border-box;
            margin-top: -1px;
        }
        .categoriesList {
            margin-bottom: 8px;
        }
        .articleCardContentTitle {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .articleCardContentDescription {
            font-size: 14px;
            line-height: 22px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .articleCardButtonReadMore {
            display: none;
        }
    }
}
@media screen and(max-width:768px) {
    .articleCard.large {
        min-height: 375px;
        height: auto;
    }
}
.articleCardLink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
@keyframes expand {
    from {
        transform: scale(0);
        opacity: 0;
    }
}

.articleGridSkeleton {
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    justify-content: space-around;
    > div {
        margin-bottom: 24px;
    }
}

@media screen and(max-width:1366px) {
    .articleGridSkeleton {
        justify-content: space-between;
    }
}
@media screen and(max-width:1200px) {
    .articleGridSkeleton {
        > div {
            margin-right: 24px;
        }
        justify-content: flex-start;
    }
}
@media screen and (max-width: 768px) {
    .articleGridSkeleton {
        > div {
            margin-right: 0;
            margin-bottom: 16px;
        }
        justify-content: center;
    }
}
